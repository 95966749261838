import { render, staticRenderFns } from "./register.vue?vue&type=template&id=bc8fbcee&scoped=true"
import script from "./register.vue?vue&type=script&lang=js"
export * from "./register.vue?vue&type=script&lang=js"
import style0 from "./register.vue?vue&type=style&index=0&id=bc8fbcee&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_vue-template-compiler@2.7.15_webpack@5.91.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc8fbcee",
  null
  
)

export default component.exports