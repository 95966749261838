import axios from 'axios'
import Cookies from 'js-cookie'

// const baseURL = 'testApi'
const baseURL = 'https://rongyue.rongyuejiaoyu.com'

const instance = axios.create({
  baseURL: baseURL,
  timeout: 15000,
  headers: {
    // 设置请求头
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    token: localStorage.getItem('vuex_token') || '',
    platform: 'pc',
    shopid: '1'
  }
})

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // const token = Cookies.get('token')
    // console.log('请求拦截器:', config)
    let token_t = localStorage.getItem('vuex_token') || ''
    // const token_t = '45eeb01c-ac70-4cf2-b248-75f49025ffce'
    config.headers.token = ` ${token_t}`
    localStorage.setItem('vuex_token', token_t)
    return config
  },
  (error) => {
    console.log('请求拦截器:', error)
    return Promise.reject(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  (res) => {
    // 对响应数据做点什么
    return res
  },
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

export default instance
